.homeScreen{
    width: 100%;
    height: 100%;
    background-image: url("../../assets/BG.svg");
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: 100%;
}

ul, li {
    list-style: none;
    padding: 0;
}

.homeScreen__header{
flex: 0.4;
display: flex;
gap: 0.8rem;
align-items: center;
flex-direction: column;
justify-content: center;
}


.homeScreen__header > p{
    font-size: 14px;
    color: #FFF;
    text-align: center;
    letter-spacing: 2.8px;
}

.homeScreen__header > p > span {
    font-weight: bold;
    margin-top: 0;
}

.homeScreen__header > img {
    width: 228.57px;
    height: 27.07px;
    display: flex;
}

.container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* padding: 0 1rem; */
    flex: 1;
    width: 100%;
}
.wrapper {
    border-radius: 15px;
    margin-left: 2rem;
}

.wrapper__title{
    font-size: 5rem;
    color: #fff;
    font-weight: lighter;
}

.wrapper__heading{
    width: fit-content;
    background: #4ccaff;
    padding: 6px 36px;
    border-radius: 20px;
    font-size: 14px;
    font-family: sans-serif;
    color: #fff;
    font-weight: normal;
}
.sessions {
    /* margin-top: 2rem; */
    border-radius: 12px;
    position: relative;
}
li {
    /* padding-bottom: 1.5rem; */
    border-left: 1px solid #abaaed;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
}
 
li:before {
    content: '';
    width: 15px;
    height: 15px;
    background: #4ccaff;
    border: 1px solid #4ccaff;
    /* box-shadow: 3px 3px 0px #bab5f8; */
    /* box-shadow: 3px 3px 0px #bab5f8; */
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 40%;
}
.time {
    color: #2a2839;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.homeScreen__footer{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 1.5rem;
}

.homeScreen__footer > p{
    text-align: center;
    font-size: 14px;
    color: #fff;
}

.homeScreen__footer-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 90%; */
    background: #fff;
    padding: 1.2rem 1.5rem;
    border-radius: 10px;
    gap: 1rem;
    cursor: pointer;
}

.homeScreen__footer-card-mid{
    flex: 1;
}

.homeScreen__footer-card-mid > p{
    font-size: 12px;
}

.homeScreen__footer-card-mid > span{
    font-size: 14px;
    font-weight: 500;
}


@media screen and (min-width: 601px) {
    .time {
        font-size: 0.9rem;
   }
}
@media screen and (max-width: 600px) {
    .time {
        margin-bottom: 0.3rem;
        font-size: 0.85rem;
   }
}

@media screen and (max-width: 380px) {

    .homeScreen__header{
        gap: 0.5rem;
        flex: 0.4;
    }

    .homeScreen__header > img{
        width: 128px;
        height: 17px;
       
    }

    h1{
        width: fit-content;
    }

   .wrapper__title{
    font-size: 3.5rem;
   }
}
@media screen and (min-width: 600px) {
    .homeScreen__header{
        width: 35%;
        margin: auto;
    }

    .container{
        width: 35%;
        margin: auto;
    }

    .homeScreen__footer{
        width: 35%;
        margin: auto;

    }

    .homeScreen{
     
        background-image: url("../../assets/BG.svg");
        background-repeat: no-repeat;
        background-size: 100%;
       
    }

    .wrapper__title{
        font-size: 3.5rem;
    }
    
}