:root {
  /* --input-font-size: 0.825rem; */
  --input-font-size: 0.825rem;

  --btn-font-size: 1rem;
  --bottom-spacing: 0.8rem;
  --border-radius: 0.5rem;
}

/* **************** MAIN - WRAPPER **************** */
.payments-card-wrapper {
  width: 35%;
  border-radius: var(--border-radius);
  background: #ffffff;
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-top: 2.5rem;
}

/* **************** HEADER **************** */
.payments-card-wrapper__header{
  background: #FFFFFF;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  -webkit-box-shadow: 1px 4px 5px 0px #00000005;
  -moz-box-shadow: 1px 4px 5px 0px #00000005;
  box-shadow: 1px 4px 5px 0px #00000005;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  
}

.payments-card-wrapper__header > h1{
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 1rem;
}


.balance-card {
  background-color: #edf4fa;
  padding: 2rem;
}

.balance-container {
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: #ffffff;
}



.amount-label {
  font-size: 1rem;
  font-weight: 600;
}

.amount-value {
  font-size: 1.375rem;
  font-weight: 500;
}

.currency-entity {
  margin-right: 0.2rem;
}

/* **************** PAYMENT - METHODS  **************** */
.bottom-spacing {
  margin-bottom: 0.8rem;
}

.payment-methods-container {
  padding: 1rem;
  padding-top: 0;
  background-color: #f8f8f8;
}

.payment-methods-title {
  padding: 1rem 0;
  font-size: 1rem;
  font-weight: 500;
}

/* ****************  APP CARDS ****************   */

.payment-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0.625rem;
  padding: 1rem 1.5rem;
  background: #ffffff;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.payment-wrapper label {
  display: flex;
  width: 100%;
  gap: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.payment-wrapper label input {
  width: 20px;
  height: 20px;
  appearance: none;
  -webkit-appearance: none;
  transition: linear 0.8s;
  height: 0;
  width: 0;
  -webkit-tap-highlight-color: transparent;
}

.payment-wrapper label input:after {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  top: 5.5px;
  left: -1px;
  border-radius: 20px;
  border: 2px solid #3a88f6;
  transition: linear 0.2s;
  cursor: pointer;
}

.payment-wrapper label input:checked:after {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  background: #3a88f6;
  transition: linear 0.2s;
  cursor: pointer;
}

.payment-wrapper label input:checked:before {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background: #fff;
  left: 5px;
  top: 11.5px;
  z-index: 1;
  cursor: pointer;
}

.payment-wrapper label input .no-before::before {
  display: none;
}

.payment-wrapper label div.payment-context {
  width: 100%;
}

.payment-option-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 10px; */
}

.option-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: black;
  flex: 1;
}

.option-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

}

.option-logo img {
  width: 30px;
  height: 30px;
}

/* ****************  FOOTER **************** */
.card-footer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-right-radius: 0.625rem;
  border-top-left-radius: 0.625rem;
  gap: 15px;
  
}

.card-footer button.submit {
  background-color: #0092ff;
  border-radius: 20px;
  outline: none;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: var(--btn-font-size);
  padding: 0.8rem 2.625rem;
  cursor: pointer;
  width: 70%;
}

.card-footer button.submit.btn-disabled {
  opacity: 0.5;
}

.return-message {
  margin: auto;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  html,
  body {
    font-size: 80%;
  }

  .payments-card-wrapper {
    width: 21.25rem;
  }

  .option-title {
    font-size: 1rem;
    font-weight: 500;
  }

  .card-footer button.submit {
    padding: 1rem 4.5rem;
    border-radius: 12px;
  }

  .return-message {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 380px) {
  .payments-card-wrapper {
    width: 18.25rem;
  }
  .payment-methods-container {
    padding: 0.5rem;
  }
}

@media only screen and (max-width: 320px) {
  .balance-container {
    padding: 0.6rem 0.8rem;
  }
  .payment-methods-title {
    font-size: 0.8rem;
    padding: 0rem;
    padding-top: 0.2rem;
  }
  .payment-wrapper {
    padding: 2rem 2.2rem;
    margin-top: 1.2rem;
    padding: 0.625rem;
  }
  .bottom-spacing {
    margin-bottom: 0.6rem;
  }
}


.selected{
  border: 1px solid #0092ff;
}


@media only screen and (max-width: 600px) {
  .payments-card-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
  }

  .balance-card{
    /* height: 85px; */
  }

  .payments-card-wrapper__mid{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .payment-methods-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll ;
    padding: 1rem 2rem;
    
  }

  .payment-methods-container__scroll{
    flex: 1;
    /* max-height: calc(100vh - 300px); */
    max-height: calc(100 * var(--vh, 1vh) - 300px);
    overflow: scroll;
  }

  .balance-container{
    /* height: 100%; */
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 1.5rem
  }

  .card-footer {
    background-color: transparent;
  }

  .card-footer button.submit {
    padding: 1.2rem 4.5rem;
    border-radius: 12px;
    width: 68%;
    height: 20px;
    font-size: 1.2rem;
  }

  .payments-card-wrapper__header{
    position: relative;
    background: #FFFFFF;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    -webkit-box-shadow: 1px 4px 5px 0px #00000005;
    -moz-box-shadow: 1px 4px 5px 0px #00000005;
    box-shadow: 1px 4px 5px 0px #00000005;
    
  }
  
  /* .payments-card-wrapper__header > h1{
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 1rem;
  } */
  

}

