:root {
  --label-font-size: 0.825rem;
  --label-heading-font-size: 0.925rem;
  --btn-font-size: 0.8rem;
  --bottom-spacing: 0.8rem;
  --border-radius: 0.5rem;
}

.payment-refund-wrapper {
  /* display: grid;
  place-items: center;
  gap: 1rem;
  width: 20rem; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.payment-refund-wrapper__header {
  background: #ffffff;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  -webkit-box-shadow: 1px 4px 5px 0px #00000005;
  -moz-box-shadow: 1px 4px 5px 0px #00000005;
  box-shadow: 1px 4px 5px 0px #00000005;
}

.payment-refund-wrapper__header > h1 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 1rem;
}

.response-heading-wrapper {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 1.2rem;
  /* width: 35%; */
  margin: 1rem auto;
}

.response-wrapper {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 1.2rem;
  width: 100%;
  width: 90%;
  margin: 1rem auto;
}

.card-header {
  font-size: 1.2rem;
  font-weight: 600;
}

.label-heading {
  font-size: var(--label-heading-font-size);
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.response-detail {
  font-size: var(--label-font-size);
  font-weight: 500;
}

.hyperstream-container {
  margin: 3rem 1.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  flex: 0.7;
}

.hyperstream-title {
  margin-bottom: 1.5rem;
}

.hyperstream-details {
  margin-bottom: 1.2rem;
}
.kyc-label-title {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 400;
}
.kyc-detail {
  font-size: 1.2rem;
  font-weight: 500;
}

.payment-refund-wrapper__mid-top-img {
  margin: 3rem auto;
}

.bottom-space {
  margin-bottom: 1rem;
}

.refund-theme-btn {
  font-size: 1.2rem!important;
  width: 100%;
  border-radius: 10px;
  background-color: #0092ff;
  padding: 1.2rem 0!important;
  color: #fff;
  font-weight: 500;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.refund-theme-btn:disabled{
  opacity: 0.5;
}

.payment-refund-wrapper__mid {
  width: 35%;
  flex: 0.99;
  display: flex;
  flex-direction: column;
}

.payment-refund-wrapper__mid-top {
  flex: 1;
  overflow: scroll;
  max-height: calc(100 * var(--vh, 1vh) - 200px);
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-refund-wrapper__mid-bottom {
  width: 90%;
  margin: auto;
  flex: 0.3;
}

.offer__title {
  font-size: 16px;
  width: 90%;
  font-weight: 500;
  margin: 0 3rem;
}

.offer__card {
  width: 76%;
  margin: 1rem auto !important;
  margin-top: .5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #0092ff;
  border-radius: 15px;
  padding: 2rem;
}

.offer__card-left {
  flex: 1;
}

.offer__card-left > p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.offer__card-leftL {
  flex: 1;
}

.offer__card-leftR {
  flex: 1;
}

.blue_title {
  font-size: 12px;
  color: #0092ff;
}

/* .btn__box{
  display: flex;
} */

@media only screen and (max-width: 600px) {
  .payment-refund-wrapper__mid {
    width: 100%;
  }
}

@media only screen and (max-width: 380px) {
  .refund-theme-btn{
    padding: 0.876rem 0!important;
  }
}

@media only screen and (min-width: 600px) {
  .payment-refund-wrapper__mid {
    width: 35%;
  }

  .payment-refund-wrapper__mid-top-img {
    width: 100% !important;
  }
  .payment-refund-wrapper__mid {
    flex: 0;
    background: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
  }

  .payment-refund-wrapper__mid-top-img {
    margin: 1rem auto;
  }

  .offer__card {
    margin: 1rem auto;
    width: auto;
  }

  .offer__title {
    margin: 2rem auto;
    width: 100%;
  }

  .payment-refund-wrapper__mid-bottom {
    width: 100%;
  }


}
