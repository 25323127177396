@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  box-sizing: content-box;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #f8fafd;
}

body{
  font-family: "Montserrat", sans-serif;
  /* background-image: url("https://decentro-public.s3.ap-south-1.amazonaws.com/invoice/img/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
ul,
li {
  margin: 0;
  padding: 0;
}
button {
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
}
input {
  outline: none;
  border: none;
}
select,
textarea {
  outline: none;
}
a {
  text-decoration: none !important;
  cursor: pointer;
}
ul {
  list-style: none;
}
div {
  outline: none;
}

