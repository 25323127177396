.background-wrapper {
  background-image: url("https://decentro-public.s3.ap-south-1.amazonaws.com/invoice/img/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  /* height: 100vh; */
  height: calc(var(--vh, 1vh) * 100);
  /* display: grid;
  place-items: center;
  align-items: start; */
 display: flex;
 align-items: center;
 justify-content: center;
}

.main-container {
  /* background: #fff;
  max-width: max-content;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem; */
  /* margin-top: 8rem; */

  width: 100%;
  height: 100%;
  display: flex;
 align-items: center;
 justify-content: center;
}

@media only screen and (max-width: 380px) {
  .main-container {
    /* padding: 2rem 2.2rem;
    margin-top: 2rem; */
  }
}

@media only screen and (max-width: 320px) {
  .main-container {
    padding: 2rem 2.2rem;
    margin-top: 1.2rem;
    padding: 1rem;
  }
}



@media only screen and (max-width: 600px) {
  .background-wrapper {
    background-image:none;
  }
}
