.statusHOC{
    width: 100%;
    /* height: 100vh; */
    height: calc(var(--vh, 1vh) * 100);
    background: #F9FBFC;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.statusHOC__header{
    background: #FFFFFF;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    -webkit-box-shadow: 1px 4px 5px 0px #00000005;
    -moz-box-shadow: 1px 4px 5px 0px #00000005;
    box-shadow: 1px 4px 5px 0px #00000005;

}

.statusHOC__header > h1 {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 1rem;
}


.statusHOC__mid{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    transform: translateY(-120px)
}

.statusHOC__mid > h1 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.statusHOC__mid > p {
   width: 90%;
    text-align: center;
    line-height: 1.5;
    /* white-space: nowrap; */
}

.statusHOC__timmer{
    width: 250px;
    height: 250px;
}

.statusHOC__footer{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    justify-content: center;
}

.footer__box{
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 90%;
    background: #F5F5F5;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 20px;
}

.failed__btn{
    position: absolute;
    bottom: 70px;
}

.second__btn{
    bottom: 130px;
}


.loader__glassHour {
    box-sizing: border-box;
    display: inline-block;
    width: 80px;
    height: 80px;
    border-top: 5px solid #0092ff;
    border-bottom: 5px solid #0092ff;
    position: relative;
    background: linear-gradient(#0092ff 30px, transparent 0) no-repeat;
    background-size: 2px 40px;
    background-position: 50% 0px;
    animation: spinx 5s linear infinite;
  }
  .loader__glassHour:before, .loader__glassHour:after {
    content: "";
    width: 40px;
    left: 50%;
    height: 35px;
    position: absolute;
    top: 0;
    transform: translatex(-50%);
    /* background: rgba(255, 255, 255, 0.4); */
    background: rgba(0, 146, 255, 0.4);
    border-radius: 0 0 20px 20px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0 0px;
    animation: lqt 5s linear infinite;
  }
  .loader__glassHour:after {
    top: auto;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    animation: lqb 5s linear infinite;
  }
  @keyframes lqt {
    0%, 100% {
      background-image: linear-gradient(#0092ff 40px, transparent 0);
      background-position: 0% 0px;
    }
    50% {
      background-image: linear-gradient(#0092ff 40px, transparent 0);
      background-position: 0% 40px;
    }
    50.1% {
      background-image: linear-gradient(#0092ff 40px, transparent 0);
      background-position: 0% -40px;
    }
  }
  @keyframes lqb {
    0% {
      background-image: linear-gradient(#0092ff 40px, transparent 0);
      background-position: 0 40px;
    }
    100% {
      background-image: linear-gradient(#0092ff 40px, transparent 0);
      background-position: 0 -40px;
    }
  }
  @keyframes spinx {
    0%, 49% {
      transform: rotate(0deg);
      background-position: 50% 36px;
    }
    51%, 98% {
      transform: rotate(180deg);
      background-position: 50% 4px;
    }
    100% {
      transform: rotate(360deg);
      background-position: 50% 36px;
    }
  }
      