/* **************** CARD - WRAPPER **************** */
:root {
  --input-font-size: 0.825rem;
  --btn-font-size: 0.8rem;
  --bottom-spacing: 0.8rem;
  --border-radius: 0.5rem;
}

.customerSignup{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
}

.signup__header{
  background: #FFFFFF;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.sign-up-heading {
  font-size: 1.2rem;
  font-weight: 600;
  color: #43425d;
  text-align: center;
  padding-bottom: 2rem;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 1rem;
  opacity: 1
}

.sign-up-subheading{
  text-align: left;
}

.mid__section{
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem; 
   
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
}

@media only screen and (max-width: 380px) {
  .form-wrapper {
    width: 18rem;
  }
 
}

.field-wrapper {
  margin-bottom: 0.8rem;
  width: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.field-wrapper label {
  display: block;
  font-size: var(--input-font-size);
  font-weight: 500;
  color: #43425d;
  margin-bottom: 0.4rem;
  align-self: flex-start;
  margin-left: -8px;
}

.loader {
    position: relative;
    width: 164px;
    height: 164px;
  }
  .loader::before , .loader::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #0092FF;
    left: 50%;
    top: 50%;
    animation: rotate 1s ease-in infinite;
}
.loader::after {
  width: 20px;
  height: 20px;
  background-color: #0092FF;
  animation: rotate 1s ease-in infinite, moveY 1s ease-in infinite ;
}

@keyframes moveY {
  0% , 100% {top: 10%}
  45% , 55% {top: 59%}
  60% {top: 40%}
}
@keyframes rotate {
  0% { transform: translate(-50%, -100%) rotate(0deg) scale(1 , 1)}
  25%{ transform: translate(-50%, 0%) rotate(180deg) scale(1 , 1)}
  45% , 55%{ transform: translate(-50%, 100%) rotate(180deg) scale(3 , 0.5)}
  60%{ transform: translate(-50%, 100%) rotate(180deg) scale(1, 1)}
  75%{ transform: translate(-50%, 0%) rotate(270deg) scale(1 , 1)}
  100%{ transform: translate(-50%, -100%) rotate(360deg) scale(1 , 1)}
}
    
      

.tandC__checkbox{
border-radius: 50%;
}

.customerSignup__input {
  display: block;
  width: 100%;
  font-size: 14px;
  color: #43425d;
  font-weight: 400;
  border: 1px solid #43425d4d;
  padding: 0.8rem 0.6rem;
  border-radius: 0.5rem;
  margin-bottom: 0.2rem;
}

.terms-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  /* margin-left: rem; */
}

.terms-text {
  flex: 1;
  font-size: 0.725rem;
}

.terms-text a {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.error-message {
  font-size: 0.8rem;
  font-weight: 400;
  align-self: flex-start;
  /* margin-bottom: 0.4rem; */
  margin-left: -8px;
  color: red;
}

.consent-error-message {
  font-size: 0.8rem;
  font-weight: 400;
  align-self: flex-start;
  margin-top: 0.2rem;
  margin-left: auto;
  margin-right: auto;
  color: red;

}

.theme-btn {
  font-size: 1.2rem;
  width: 86%;
  border-radius: 10px;
  background-color: #0092ff;
  padding: 1.2rem 1.2rem;
  margin-top: 1rem;
  color: #fff;
  font-weight: 500;
  display: grid;
  height: 1.7rem;
  place-items: center;
}

.theme-btn__disabled {
  font-size: 1.2rem;
  width: 86%;
  border-radius: 10px;
  background-color: #DEDEDE;
  padding: 1.2rem 1.2rem;
  margin-top: 1rem;
  color: #A5A5A5;
  font-weight: 500;
  opacity: 0.5;
  /* display: grid;
  place-items: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.7rem;

}

.custom-loader {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #fff 94%, #0000) top/3px 3px
      no-repeat,
    conic-gradient(#0000 30%, #fff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: s3 1s infinite linear;
}

.signup__footer{
  padding: 1rem 0;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}


@media only screen and (max-width: 600px) {
  .mid__section{
    flex: 1;
    width: 100%;
    padding: 0;
    border-radius: 0px;

  }

  .terms-container {
    margin-left: 1rem!important;
    justify-content: center;
  }


  .form-wrapper{
    flex: 0.9;
  }

  .form__top{
    width: 90%;
    flex: 0.2;
  }

  .form__mid{
    width: 86%;
    flex: 1;

  }

  .form__bottom{
    width: 90%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .form-wrapper{
    width: 90%;
  }
  
  .field-wrapper{
    width: 100%;
  }

  input{
    height: 1.7rem;
  }
}